import React from "react"
import Layout from "../layouts/layout"
import Card, { CardContent, CardHeader } from "../components/Card"
import { UnsubscribeAlt } from "../components/Unsubscribe"
import Success from "../components/Response/success"
import Fail from "../components/Response/fail"
import Loading from "../components/Loader"
import { useManageSubscription } from "../contexts/manageContext"

const sitekey = "6Lfv3OciAAAAAISnTAWSrOJLLXFhZhOSv7CmauoI"

const UnsubscribePage = ({ location }) => {
  return (
    <Layout page="manage">
      <Card>
        <CardHeader title={`Newsletter Service`} />
        <CardContent>
          <Wrapper location={location} />
        </CardContent>
      </Card>
    </Layout>
  )
}

function Wrapper({ location }) {
  const {
    pageLoading,
    submitted,
    response,
    responseText,
    handleUnsubscribe,
    recaptchaRef,
    handleVerify,
    handleExpiry,
    isVerified,
  } = useManageSubscription()

  const urlParams = new URLSearchParams(location.search)
  const userId = urlParams.get("userId")

  if (submitted && response === "success") {
    return <Success title="Successfully Unsubscribed!" message={responseText} />
  } else if (submitted && response === "fail") {
    return <Fail />
  } else {
    return (
      <UnsubscribeContent
        pageLoading={pageLoading}
        handleUnsubscribe={handleUnsubscribe}
        userId={userId}
        recaptchaRef={recaptchaRef}
        handleVerify={handleVerify}
        handleExpiry={handleExpiry}
        isVerified={isVerified}
      />
    )
  }
}

function UnsubscribeContent({
  pageLoading,
  handleUnsubscribe,
  userId,
  recaptchaRef,
  handleExpiry,
  handleVerify,
  isVerified,
}) {
  return (
    <>
      <Loading isLoading={pageLoading} />

      <UnsubscribeAlt
        handleUnsubscribe={handleUnsubscribe}
        userId={userId}
        recaptchaRef={recaptchaRef}
        handleVerify={handleVerify}
        handleExpiry={handleExpiry}
        sitekey={sitekey}
        isVerified={isVerified}
      />
    </>
  )
}

export default UnsubscribePage
